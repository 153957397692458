import request from '@/util/request'
export default {
    // 获取首页数据
    getHome(){
        return request({
            url:"/api/portal/home",
            method:"post",
        })
    },
    // 获取网站设置
    getSite(){
        return request({
            url:"/api/portal/site",
            method:"post"
        })
    },
    // 获取关于我们的页面
    getAbout(){
        return request({
            url:"/api/portal/about",
            method:"post"
        })
    },
    // 获取服务
    getServe(){
        return request({
            url:"/api/portal/services",
            method:"post"
        })
    },
    // 政策文件
    getPolicy(){
        return request({
            url:"/api/portal/policy",
            method:"post"
        })
    },
    // 获取博客数据
    getBlog(query){
        return request({
            url:"/api/portal/blog",
            data:{...query},
            method:"post"
        })
    },
    // 博客详情
    getBlogDetail(query){
        return request({
            url:"/api/portal/blogDetail",
            data:{...query},
            method:"post"
        })
    },
    // 获取帮助
    getHelp(){
        return request({
            url:"/api/portal/help",
            method:"post"
        })
    },
    // 隐私协议
    getPrivacy(){
        return request({
            url:"/api/portal/privacy",
            method:"post"
        })
    },
    // 常见问题
    getFaq(){
        return request({
            url:"/api/portal/question",
            method:"post"
        })
    },
    // 添加留言信息
    addMessage(query){
        return request({
            url:"/api/portal/addMessage",
            method:"post",
            data:{...query}
        })
    },
    // 注册
    getRegister(query){
        return request({
            url:"/api/index/register",
            method:"post",
            data:{...query}
        })
    },
    // 登录
    login(query){
        return request({
            url:"/api/index/login",
            method:"post",
            data:{...query}
        })
    },
    // 邮箱验证吗
    getEmail(query){
        return request({
            url:"/api/index/sendMail",
            method:"post",
            data:{...query}
        })
    },
    // 忘记密码
    getForget(query){
        return request({
            url:"/api/index/forget",
            method:"post",
            data:{...query}
        })
    },
    // 谷歌登录
    gooleLogin(query){
        return request({
            url:"/api/index/googleLogin",
            method:"post",
            data:{...query}
        })
    },
    // 获取其他设置
    getOtherSite(){
        return request({
            url:"/api/portal/other",
            method:"post"
        })
    }
}